.border-none[data-v-e7e0abc4]:before {
  display: none;
}
.border-none[data-v-e7e0abc4]:after {
  display: none;
}
.flex-fix[data-v-e7e0abc4] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-e7e0abc4]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-e7e0abc4] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-e7e0abc4] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-e7e0abc4] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-e7e0abc4] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-e7e0abc4] {
  *zoom: 1;
}
.clear-fix[data-v-e7e0abc4]:before,
.clear-fix[data-v-e7e0abc4]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.pay_waitting[data-v-e7e0abc4] {
  text-align: center;
}
.pay_waitting .kongbai[data-v-e7e0abc4] {
  width: 100%;
  height: 4.5rem;
}
.pay_waitting .txt[data-v-e7e0abc4] {
  font-size: 0.5rem;
  font-weight: bold;
}
.pay_waitting .tip[data-v-e7e0abc4] {
  margin-top: 0.3rem;
  color: gray;
  font-size: 0.25rem;
}
.pay_waitting .content[data-v-e7e0abc4] {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 10%;
  left: 50%;
  -webkit-transform: translate(-50%, -10%);
  transform: translate(-50%, -10%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.pay_waitting .content .outer[data-v-e7e0abc4] {
  width: 2rem;
  height: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 100%;
  border: 2px solid #2196F3;
  border-top-color: transparent;
  border-bottom-color: transparent;
  -webkit-animation: 1.5s outer-ani-e7e0abc4 ease-in-out 0.2s infinite;
  animation: 1.5s outer-ani-e7e0abc4 ease-in-out 0.2s infinite;
}
.pay_waitting .content .inner[data-v-e7e0abc4] {
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: #2196F3;
  -webkit-animation: 1.5s inner-ani-e7e0abc4 ease-in-out 0.2s infinite;
  animation: 1.5s inner-ani-e7e0abc4 ease-in-out 0.2s infinite;
}
@keyframes outer-ani-e7e0abc4 {
0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}
@-webkit-keyframes outer-ani-e7e0abc4 {
0% {
    -webkit-transform: rotate(0deg);
}
50% {
    -webkit-transform: rotate(180deg);
}
100% {
    -webkit-transform: rotate(360deg);
}
}
@-webkit-keyframes inner-ani-e7e0abc4 {
0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
}
50% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
100% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
}
}
@keyframes inner-ani-e7e0abc4 {
0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
}
50% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
100% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
}
}
@-webkit-keyframes innner-ani-e7e0abc4 {
0% {
    -webkit-transform: scale(0.1);
}
50% {
    -webkit-transform: scale(1);
}
100% {
    -webkit-transform: scale(0.1);
}
}
