.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.pay_waitting {
  text-align: center;
}
.pay_waitting .kongbai {
  width: 100%;
  height: 4.5rem;
}
.pay_waitting .txt {
  font-size: 0.5rem;
  font-weight: bold;
}
.pay_waitting .tip {
  margin-top: 0.3rem;
  color: gray;
  font-size: 0.25rem;
}
.pay_waitting .content {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 10%;
  left: 50%;
  -webkit-transform: translate(-50%, -10%);
  transform: translate(-50%, -10%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.pay_waitting .content .outer {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 2px solid #2196F3;
  border-top-color: transparent;
  border-bottom-color: transparent;
  -webkit-animation: 1.5s outer-ani ease-in-out 0.2s infinite;
  animation: 1.5s outer-ani ease-in-out 0.2s infinite;
}
.pay_waitting .content .inner {
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: #2196F3;
  -webkit-animation: 1.5s inner-ani ease-in-out 0.2s infinite;
  animation: 1.5s inner-ani ease-in-out 0.2s infinite;
}
@keyframes outer-ani {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes outer-ani {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes inner-ani {
  0% {
    transform: scale(0.1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.1);
  }
}
@-webkit-keyframes innner-ani {
  0% {
    -webkit-transform: scale(0.1);
  }
  50% {
    -webkit-transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.1);
  }
}
